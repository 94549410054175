﻿import { booking_group } from "./../objects/v1_2/Booking/Booking_Group";
import { passenger } from "./../objects/v1_2/Booking/Passenger";
import { trip } from "./../objects/v1_2/Booking/Trip";
import { trip_passenger } from "./../objects/v1_2/Booking/Trip_Passenger";

import { booking_search_group } from "./../objects/v1_2/Booking_Details/booking_search_group";

import { response_login } from "../objects/agent/identity/v1_3/response_login";

import { booking } from "../objects/v1_2/Booking_Put/booking";
import { booking_note } from "../objects/v1_2/Booking_Put/booking_note";
import { booking_passenger } from "../objects/v1_2/Booking_Put/booking_passenger";

import { booking_info } from "../objects/v1_2/Booking_Details/booking_info";
import { get_booking } from "../objects/v1_3/booking/get_booking/booking";

import { booking_details } from "../objects/v1_2/Booking_Details/booking_details";
import { booking_trip_room } from "../objects/v1_2/Booking_Details/booking_trip_room";
import { client_booking } from "../objects/v1_2/Booking_Details/client_booking";

import { booking_search_request } from "../objects/v1_3/booking/search/request";
import { booking_search_response } from "../objects/v1_3/booking/search/response";

import { Security } from "./security";
import { Rest_API } from "../../system/services/rest_api";
import { add_on } from '../objects/v1_2/Booking/Add_On';
import { age_cat } from "../objects/v1_3/web_engine/age_cat"

import { currency } from "../objects/v1_2/Currency/currency";

import { message_request } from "../objects/v1_3/booking/message/request"
import { return_status } from "../objects/v1_3/return_status"

import { stringifyQuery } from 'vue-router';
import { room } from "../objects/v1_2/Booking/Room";
import { booking_confirmation } from "../objects/v1_2/Booking_Put/booking_confirmation";
import { response_booking } from "../objects/v1_3/booking/search/response_booking";

import { request } from "../objects/v1_2/Booking_Delete/Request/request";
import { request_trip } from "../objects/v1_2/Booking_Delete/Request/request_trip";
import { request_trip_passenger } from "../objects/v1_2/Booking_Delete/Request/request_trip_passenger";
import { response } from "../objects/v1_2/Booking_Delete/Response/response";
import { product_search } from "../objects/v1_3/product/search/product_search";
import { Product } from '../services/product';

export class Booking {

    Rest: Rest_API = new Rest_API();
    Secure: Security = new Security();

    public async Quote_Booking(store: any) {

        store.commit("Set_isQuoteCalculating", { isQuoteCalculating: true });

        let Basket: booking_group = store.getters.get_Basket;
        if (store.getters.get_Agent_Login?.token?.access_token != null) {
            Basket.booking_information.token = store.getters.get_Agent_Login.token;
        }

        if (Basket?.trips[0]?.tour_id != "" && Basket?.trips[0]?.tour_id != undefined) {
            return await this.Rest.Post_Data_Secure<booking_group, booking_search_group>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/quote", await this.Secure.Get_Hash_Password(), Basket).then(response => {
                store.commit("Set_isQuoteCalculating", { isQuoteCalculating: false });
                return response.json();
            });
        }

        store.commit("Set_isQuoteCalculating", { isQuoteCalculating: false });

        return null;
    }
    public async Book_Trip(store: any) {

        let Basket: booking_group = store.getters.get_Basket;
        if (store.getters.get_Agent_Login?.token?.access_token != null) {
            Basket.booking_information.token = store.getters.get_Agent_Login.token;
        }

        return this.Rest.Post_Data_Secure<booking_group, booking_search_group>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings", await this.Secure.Get_Hash_Password(), Basket);
    }  
    public async Get_Book(Booking_ID: string, agent_response_login: response_login) {
        var Booking: get_booking = new get_booking();
        Booking.token = agent_response_login.token;

        return await this.Rest.Post_Data_Secure<get_booking, booking_info>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + Booking_ID, await this.Secure.Get_Hash_Password(), Booking).then(response => { return response.json(); });
    }
    public async Get_Booking(store: any, booking_id: string) : Promise<any> {
        return await this.Rest.Get_Data_Secure<booking_group>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + booking_id, await this.Secure.Get_Hash_Password()).then(response => {
            return response.json();
        });

        return null;
    }
    public async Get_Booking_Session(store: any, booking_id: string, session_id: string) : Promise<response_booking> {
        let booking_information = new get_booking();
        booking_information.booking_reference = booking_id;
        booking_information.token.refresh_token = session_id;

        var url = import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + booking_id;
        const result : response_booking = await this.Rest.Post_Data_Secure<get_booking, response_booking>(url, await this.Secure.Get_Hash_Password(), booking_information).then(response => {
            let json = response.json();
            return json;
        });

        store.commit("Setup_Booking", { Booking_Details: result });

        return result;
    }
    public async Setup_Booking(store: any) {
        store.commit("Setup_Booking", { Booking_Details: await this.Quote_Booking(store) });  
    }
    public async Update_Booking_Promo(Booking_ID: string, Booking_Info: booking_group, promo_code: string) {
        let Put_Booking: booking = new booking();
        Put_Booking.trips = [];

        Booking_Info.trips.forEach(booking_trip => {
            let Put_Trip: trip = new trip();
            Put_Trip.booking_identifier = booking_trip.booking_identifier;
            Put_Trip.promo_code = promo_code;
            Put_Booking.trips.push(Put_Trip);
        });

        return this.Rest.Put_Data_Secure<booking, booking>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + Booking_ID, await this.Secure.Get_Hash_Password(), Put_Booking);
    }
    public async Update_Booking_Passengers(Booking_Info: booking_search_group, agent_response_login: response_login, currency_info: currency) {
        let Put_Booking: booking = new booking();
        Put_Booking.passengers = [];

        Put_Booking.booking_information.iso_currency_code = currency_info.currency_info.iso_currency_code;
        Put_Booking.booking_information.token = agent_response_login.token;

        Booking_Info.passengers.forEach(passenger => {
            Put_Booking.passengers.push(passenger);
        });

        return this.Rest.Put_Data_Secure<booking, booking>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + Booking_Info.group_booking_id, await this.Secure.Get_Hash_Password(), Put_Booking);
    }
    public async Add_Booking_Notes(Booking_Info: booking_search_group, agent_response_login: response_login, currency_info: currency, type: string, note: string) {
        let Put_Booking: booking = new booking();
        let Booking_Note: booking_note = new booking_note();
        Booking_Note.type = type;
        Booking_Note.note = note;

        Put_Booking.booking_information.iso_currency_code = currency_info.currency_info.iso_currency_code;
        Put_Booking.booking_information.token = agent_response_login.token;

        Put_Booking.booking_confirmation.booking_notes.push(Booking_Note);

        return this.Rest.Put_Data_Secure<booking, booking>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + Booking_Info.group_booking_id, await this.Secure.Get_Hash_Password(), Put_Booking);
    }
    public async Update_Booking_Agent_Payment(Booking_Info: booking_search_group, agent_response_login: response_login, Payment_Method: string, agent_reference: string, currency_info: currency, Full_Payment: boolean, Deposit_Payment: boolean) {
        let Put_Booking: booking = new booking();

        Put_Booking.booking_confirmation.payment.full_payment = Full_Payment;
        Put_Booking.booking_confirmation.payment.deposit_payment = Deposit_Payment;

        Put_Booking.booking_confirmation.reference = agent_reference;
        Put_Booking.booking_information.iso_currency_code = currency_info.currency_info.iso_currency_code;
        Put_Booking.booking_information.token = agent_response_login.token;

        return this.Rest.Put_Data_Secure<booking, booking>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + Booking_Info.group_booking_id, await this.Secure.Get_Hash_Password(), Put_Booking);
    }
    public get_price(trip: booking_info): number {
        let Total_Amount: number = 0;
        trip.passengers.forEach(function (passenger) {
            Total_Amount += passenger.price;
        });

        return Total_Amount;
    }
    public get_total_price(booking: booking_search_group, store: any): number {
        let Total_Amount: number = 0;
        store.getters.get_Booking?.trips?.forEach( (trip: booking_info) => {
            if (this.Add_On(trip, store) == false) {
                trip?.passengers?.forEach(function (passenger: client_booking) {

                    Total_Amount += passenger.price;
                });
            }
        });

        return Total_Amount;
    }
    public get_Passenger(passenger_identifier: string, booking: booking_search_group): string {
        let Passenger_Name: string = "";
        booking?.passengers?.forEach(function (passenger) {
            if (passenger.passenger_identifier == passenger_identifier) {
                if (passenger.family_name == null) {
                    Passenger_Name = passenger.first_name;
                } else {
                    Passenger_Name = passenger.first_name + " " + passenger.family_name;
                }
            }
        });

        return Passenger_Name;
    }
    public get_Passenger_Age_Cat(passenger_identifier: string, booking: booking_search_group): string {
        let Passenger_Age_Cat: string = "";
        booking?.passengers?.forEach(function (passenger) {
            if (passenger.passenger_identifier == passenger_identifier) {
                Passenger_Age_Cat = passenger.age_category;
            }
        });

        return Passenger_Age_Cat;
    }
    public getRoom_Price(room_type: string | null, trip: booking_info): number {
        let Room_Price: number = 0;
        trip?.reservation_rooms?.forEach(function (room) {
            if (room.room_type == room_type) {
                room.reservation_room_links.forEach(function (room_link) {
                    trip?.passengers?.forEach(function (passenger) {
                        if (passenger.trip_passenger_identifier == room_link.trip_passenger_identifier) {
                            Room_Price += passenger.price;
                        }
                    });
                });
            }
        });

        return Room_Price;
    }
    public getRoom_Indevidual_Price(room_info: booking_trip_room, trip: booking_info): number {
        let Room_Price: number = 0;

        room_info.reservation_room_links.forEach(function (room_passenger) {
            trip?.passengers.forEach(function (passenger) {
                if (passenger.trip_passenger_identifier == room_passenger.trip_passenger_identifier) {
                    Room_Price += passenger.price;
                }
            });
        });

        return Room_Price;
    }
    public getTotal_Add_Ons(get_Basket: booking_group): number {
        let Add_Ons: number = 0;

        get_Basket.trips.forEach(function (Trip) {
            Trip.add_ons.forEach(function (Add_On) {
                Add_Ons++;
            });
        });

        return Add_Ons;
    }
    public getRoom_Type(trip_passenger_identifier: string, trip: booking_info): string {
        let Room_Type: string = "";
        trip?.reservation_rooms?.forEach(function (room) {
            room?.reservation_room_links.forEach(function (room_link) {
                if (room_link.trip_passenger_identifier == trip_passenger_identifier) {
                    Room_Type = room.room_type;
                };
            });
        });

        return Room_Type;
    }
    public get_total_outstanding(booking: booking_search_group): number {
        let Total_Amount: number = 0;
        booking?.trips?.forEach(function (trip) {
            trip?.passengers?.forEach(function (passenger) {
                Total_Amount += passenger.outstanding;
            });
        });

        return Total_Amount;
    }
    public get_Expiry_Date(booking: booking_search_group): string {
        let Output: string = "";
        booking?.trips?.forEach(function (trip) {
            trip?.passengers?.forEach(function (passenger) {
                if (passenger?.expiry_time + "" != "") {
                    Output = passenger.expiry_time;
                }
            });
        });

        return Output;
    }
    public get_Total_Deposit(booking: booking_search_group): number {
        let Total_Amount: number = 0;
        booking?.trips?.forEach(function (trip) {
            trip?.passengers?.forEach(function (passenger) {
                Total_Amount += passenger.deposit;
            });
        });

        return Total_Amount;
    }
    public get_Balance(booking: booking_search_group): number {
        return this.get_total_outstanding(booking) - this.get_Total_Deposit(booking);
    }
    public get_Paid(booking: booking_search_group): number {
        let Total_Amount: number = 0;
        booking?.trips?.forEach(function (trip) {
            trip?.passengers?.forEach(function (passenger) {
                Total_Amount += passenger.paid;
            });
        });

        return Total_Amount;
    }
    public get_full_total_price(booking: booking_search_group, store: any): number {
        return this.get_total_price(booking, store);
    }
    public get_full_nett_total(booking: booking_search_group, store: any): number {
        let Total_Amount: number = 0;
        store.getters.get_Booking?.trips?.forEach((trip: booking_info) => {
            if (this.Add_On(trip, store) == false) {
                trip?.passengers?.forEach(function (passenger: client_booking) {

                    Total_Amount += passenger.price - passenger.commission;
                });
            }
        });

        return Total_Amount;
    }
    public get_discount(booking: booking_search_group): number {
        let Discount_Amount: number = 0;
        booking?.trips?.forEach(function (trip) {
            trip?.passengers?.forEach(function (passenger) {
                Discount_Amount += passenger.discount;
            });
        });

        return Discount_Amount;
    }
    public get_Discount_Percentage(booking: booking_search_group, store: any): number {
        let Discount_Percentage: number = 0;

        booking?.trips?.forEach( (trip: booking_info) => {
            if (this.Add_On(trip, store) == false) {
                trip?.passengers?.forEach(function (passenger: client_booking) {
                    Discount_Percentage = passenger.discount_percentage;
                });
            }
        });

        return Discount_Percentage;
    }
    public get_Discount_Description(booking: booking_search_group, store: any): string {
        let Discount_Description: string = "";

        booking?.trips?.forEach((trip: booking_info) => {
            if (this.Add_On(trip, store) == false) {
                trip?.passengers?.forEach(function (passenger: client_booking) {
                    if (passenger.discount_description + "" != "") {
                        Discount_Description = passenger.discount_description;
                    }
                });
            }
        });

        return Discount_Description;
    }
    public get_add_ons(store: any): number {
        let Add_On_Amount: number = 0;
        store.getters.get_Booking?.trips?.forEach( (trip: booking_info) => {
            if (this.Add_On(trip, store) == true) {
                Add_On_Amount += this.get_price(trip);
            }
        });

        return Add_On_Amount;
    }
    public Trip_Selected(tour_id: string, booking: booking_search_group): boolean {
        let Output: boolean = false;

        booking?.trips?.forEach(function (trip) {
            if (tour_id == trip.tour_id) {
                Output = true;
            }
        });

        return Output;
    }
    public Passenger_Total(age_category: string, booking: booking_group): number {

        //console.log("Passenger Total - " + age_category);
        //console.log(booking);

        let Passengers: number = 0;
        booking.passengers.forEach(function (Passenger) {
            if (Passenger.age_category == age_category) {
                Passengers++;
            }
        });

        return Passengers;
    }
    Add_On(trip: booking_info, store: any): boolean {
        let Add_On_Tour: boolean = false;
        store.getters.get_Basket?.trips[0]?.add_ons?.forEach(function (Add_On: add_on) {
            if (Add_On.tour_id == trip.tour_id) {
                Add_On_Tour = true;
            }
        });
        if(this.isPaymentLink() && Add_On_Tour == false)
        {
            this.payment_link_addon(trip, store).then(result => {
                //alert(trip.tour_name + " is addon: " + result);
                return result;
            });
        }
        return Add_On_Tour;
    }
    async payment_link_addon(trip: booking_info, store: any): Promise<boolean> {
        let product_search: product_search;
        const Prod = new Product();
        product_search = await Prod.Get_Product_Search();
        let isAddon = true;
        product_search.products.forEach(product => {
                if(product.tour_id == trip.tour_id)
                {
                    isAddon = false;
                }
        });
        return isAddon;
    }
    isPaymentLink(): boolean {
        return window.location.href.includes("paylink");
    }
    Pricing_Age_Category(store: any): string {
        if (store.getters.get_Basket?.passengers?.length > 0) {
            return store.getters.get_Basket?.passengers[0].age_category;
        }

        return String(import.meta.env.VITE_DEFAULT_AGE_CATEGORY);
    }
    Passenger_Details(add_on: boolean, age_cat: age_cat, tour_id: string, get_Basket: booking_group): string {
        let Output: string = "Please select passenger(s)";
        var agecat = new Array<string>();

        if (this.Get_Passengers(add_on, tour_id, get_Basket).length > 0) {
            Output = this.Get_Passengers(add_on, tour_id, get_Basket).length + " passenger(s)";

            var distintagecat = age_cat?.categories;
            if (distintagecat != null) {
                Output = "";
                for (var i = 0; i < distintagecat?.length; i++) {
                    var catname = distintagecat[i].name;
                    let catcount = this.Get_Passengers(add_on, tour_id, get_Basket).filter(passenger => passenger.age_category == catname).length;

                    if (catcount > 0) {
                        let paxcatdisplay = catcount + " " + catname

                        if (catcount > 1) {
                            let catsuffix = "s";

                            if (catname == "Child") {
                                catsuffix = "ren";
                            }

                            paxcatdisplay = paxcatdisplay + catsuffix;
                        }

                        if (Output != "") {
                            Output = Output + ", ";
                        }
                        Output = Output + paxcatdisplay;
                    }
                }
            }


        }

        return Output;
    }
    Get_Passengers(add_on: boolean, tour_id: string, get_Basket: booking_group): passenger[] {

        let Pax_Output: passenger[] = get_Basket?.passengers ?? [];
        let passengers: passenger[] = get_Basket?.passengers ?? [];

        if (add_on == true) {
            get_Basket.trips.forEach(function (Trip: trip) {
                Trip.add_ons.forEach(function (add_on: add_on) {
                    if (add_on.tour_id == tour_id) {
                        Pax_Output = [];
                        add_on.passengers.forEach(function (passenger: trip_passenger) {
                            Pax_Output.push(passengers[passenger.index]);
                        });
                    }
                });
            });
        }

        return Pax_Output;
    }
    Valid_Overall_Passenger(get_Basket: booking_group): boolean {
        let Valid_Output_Pax: boolean = true;
        this.Valid_Passengers(get_Basket).forEach((Valid_Pax: string) => {
            if (Valid_Pax != "") {
                Valid_Output_Pax = false;
            }
        });

        return Valid_Output_Pax;
    }
    Valid_Passenger_Complete(passenger_identifier: string, get_Basket: booking_group): boolean {
        let Valid_Output_Pax: boolean = false;
        get_Basket.passengers.forEach((Passenger: passenger) => {
            if (passenger_identifier == Passenger.passenger_identifier) {
                Valid_Output_Pax = false;
                if (this.Valid_Passenger(Passenger).length == 0) {
                    Valid_Output_Pax = true;
                }
            }
        });

        return Valid_Output_Pax;
    }
    Valid_AllPassengers_Complete(get_Basket: booking_group): boolean {
        let Valid_Output_AllPax: boolean = true;

        get_Basket.passengers.forEach((Passenger: passenger) => {
            Valid_Output_AllPax = Valid_Output_AllPax && (this.Valid_Passenger(Passenger).length == 0);
        });

        return Valid_Output_AllPax;
    }
    Valid_Passengers(get_Basket: booking_group): string[] {
        let Passengers_Output: string[] = [];

        get_Basket.passengers.forEach((Passenger: passenger) => {
            let Valid_Pax: string = "";

            this.Valid_Passenger(Passenger).forEach((Pax: string) => {
                Valid_Pax += " " + Pax;
            });

            Passengers_Output.push(Valid_Pax);
        });

        return Passengers_Output;
    }
    Valid_Passenger(Passenger: passenger): string[] {
        let Output_Field: string[] = [];

        Output_Field = this.Check_Field(Passenger, Output_Field, "first_name", Passenger.first_name);
        Output_Field = this.Check_Field(Passenger, Output_Field, "family_name", Passenger.family_name);
        Output_Field = this.Check_Field(Passenger, Output_Field, "email", Passenger.email);
        Output_Field = this.Check_Field(Passenger, Output_Field, "iso_nationality_sate", Passenger.iso_nationality_sate);
        Output_Field = this.Check_Field(Passenger, Output_Field, "date_of_birth", Passenger.date_of_birth);
        Output_Field = this.Check_Field(Passenger, Output_Field, "telephone_number", Passenger.telephone_number);
        Output_Field = this.Check_Field(Passenger, Output_Field, "emergency_contact", Passenger.emergency_contact);
        Output_Field = this.Check_Field(Passenger, Output_Field, "emergency_number", Passenger.emergency_number);

        return Output_Field;
    }
    Check_Field(Passenger: passenger, Output_Field: string[], field_name: string, field_value: string | null): string[] {
        if (this.require_field(Passenger, field_name) == true) {
            if (field_value + "" == "" || field_value == null) {
                Output_Field.push(field_name);
            }
        }

        return Output_Field;
    }
    require_field(Passenger: passenger, Field: string): boolean {
        let Require_Field: boolean = false;
        Passenger.required_fields.forEach(function (Input_Field: string) {
            if (Input_Field == Field) {
                Require_Field = true;
            }
        });

        return Require_Field;
    }
    public async Booking_Search(store: any): Promise<booking_search_response> {
        let Search: booking_search_request = new booking_search_request();
        if (store.getters.get_Agent_Login?.token?.access_token != null) {
            Search.token = store.getters.get_Agent_Login.token;
        }

        return await this.Rest.Post_Data_Secure<booking_search_request, booking_search_response>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/search", await this.Secure.Get_Hash_Password(), Search).then(response => { return response.json(); });
    }
    public async Booking_Message(store: any, message_type: string, email: string, booking_id: string): Promise<return_status> {
        let Message_Request: message_request = new message_request();

        Message_Request.booking_id = booking_id;
        Message_Request.email = email;
        Message_Request.message_type = message_type;
        Message_Request.booking_information.iso_currency_code = "AUD";
        if (store.getters.get_Agent_Login?.token?.access_token != null) {
            Message_Request.booking_information.token = store.getters.get_Agent_Login.token;
        }

        return await this.Rest.Post_Data_Secure<message_request, return_status>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/message", await this.Secure.Get_Hash_Password(), Message_Request).then(response => { return response.json(); });
    }
    public async Cancel_Booking(store: any, group_booking_id: number, Request: request): Promise<response> {
        Request.booking_information.iso_currency_code = "AUD";
        Request.booking_information.token = store.getters.get_Agent_Login.token;

        return await this.Rest.Delete_Data_Secure<request, response>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/bookings/" + group_booking_id, await this.Secure.Get_Hash_Password(), Request).then(response => { return response.json(); });
    }

}