﻿import { defineComponent } from 'vue';

import { request_login } from "../../booking_engine/objects/agent/identity/v1_3/request_login"
import { response_login } from "../../booking_engine/objects/agent/identity/v1_3/response_login"

import { Agent_Login } from "../../booking_engine/services/agent_login";

let Login: Agent_Login = new Agent_Login();

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
        return {
            Login_Request: new request_login(),
            logging_in: false as boolean
        }
    },
    methods: {
        async agent_login() {
            if(this.Login_Request.user_name != null && this.Login_Request.password != null)
            {
                this.logging_in = true;
                this.$store.commit("Set_Agent_Login", { Agent_Login: await Login.Login(this.Login_Request), Agent_Request_Login: this.Login_Request });
                this.logging_in = false;

                if (this.$store.getters.get_Agent_Login.valid_login == false) {
                    window.alert(this.$store.getters.get_Agent_Login.error_message);
                }
            }
        },
        Forgot_Password() {
            this.$router.push('/login/forgot_password');
        },
        Register() {
            this.$router.push('/login/register');
        },
        getContactInfo(): string {
            if (import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT != undefined) {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT);
            }
            else {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
            }
        },
        loginText(): string {
            if(this.logging_in == true)
            {
                return "Logging In";
            }
            else
            {
                return "Login";
            }
        },
        hide_new_agent_link(): boolean {
            if (import.meta.env.VITE_HIDE_NEW_AGENT_LINK == "true") {
                return true;
            }
            else {
                return false;
            }
        }
    },
    mounted() {
    }
});