﻿<template>
    <div class="basket-tours w-full">
        <div class="card-h basket">

            <!--Desktop view only-->
            <div :id="'basket_' + trip.tour_id" class="outter flex flex-wrap lg:flex-nowrap" v-for="trip in $store.getters.get_Booking.trips">
                <div v-if="zeroPriceCheck(trip)" class="cover w-4/12" :class="{'hidden': isMobile()}">
                    <img :src="get_Image(trip.tour_id, 900, 0)" :alt="imageAltText(trip.tour_id)">
                </div>
                <div v-if="zeroPriceCheck(trip)" class="content w-8/12 lg:flex-grow" :class="{'hidden': isMobile()}">
                    <div class="innner">
                        <h5>{{getProductName(trip)}}</h5>
                        <ul class="route" :hidden="!valid_Times(trip_ends(trip.departure_date, trip.tour_id))"> 
                            <li>
                                <p class="route-location" :hidden="start_location(trip.tour_id) == null">Starts in {{start_location(trip.tour_id)}}</p>
                                <span class="route-date">{{trip_starts(trip.departure_date, trip.tour_id)}}</span>
                                <span class="route-date">{{trip_checkintime(trip.tour_id)}}</span>
                            </li>
                            <li :hidden="trip_ends(trip.departure_date, trip.tour_id) == trip_starts(trip.departure_date, trip.tour_id)">
                                <p class="route-location" :hidden="end_location(trip.tour_id) == null">Ends in {{end_location(trip.tour_id)}}</p>
                                <span class="route-date">{{trip_ends(trip.departure_date, trip.tour_id)}}</span>
                            </li>
                        </ul>
                        <p :hidden="!add_on_tour(trip)"><span v-html="FormatDescription(get_Tour_Details(trip.tour_id)?.marketing_information?.description)"></span></p>
                    </div>
                    <div v-if="!is_non_listed_tour(tour_id)" class="see-more flex" :hidden="add_on_tour(trip)">
                        <p class="icon">
                            <a v-if="!add_on_tour(trip)" @click="tour_selected(trip.tour_id)">
                                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-route fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="route" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432 352H320c-26.5 0-48-21.5-48-48s21.5-48 48-48h96s96-107 96-160-43-96-96-96-96 43-96 96c0 34.6 40.9 92.2 69.3 128H320c-44.1 0-80 35.9-80 80s35.9 80 80 80h112c26.5 0 48 21.5 48 48s-21.5 48-48 48H122.7c28.4-35.8 69.3-93.4 69.3-128 0-53-43-96-96-96S0 299 0 352s96 160 96 160h336c44.1 0 80-35.9 80-80s-35.9-80-80-80zM352 96c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zM32 352c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zm64-16c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32zM416 80c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32z" class=""></path></svg>
                                See full itinerary
                            </a>
                            <a v-if="add_on_tour(trip)" @click="tour_selected(trip.tour_id)">
                                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-route fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="route" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432 352H320c-26.5 0-48-21.5-48-48s21.5-48 48-48h96s96-107 96-160-43-96-96-96-96 43-96 96c0 34.6 40.9 92.2 69.3 128H320c-44.1 0-80 35.9-80 80s35.9 80 80 80h112c26.5 0 48 21.5 48 48s-21.5 48-48 48H122.7c28.4-35.8 69.3-93.4 69.3-128 0-53-43-96-96-96S0 299 0 352s96 160 96 160h336c44.1 0 80-35.9 80-80s-35.9-80-80-80zM352 96c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zM32 352c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zm64-16c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32zM416 80c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32z" class=""></path></svg>
                                See add-on description
                            </a>
                        </p>
                    </div>
                </div>

                <!--mobile and tablet view only-->
                <div v-if="zeroPriceCheck(trip)" class="cover-mobile " :class="{'hidden': !isMobile()}">
                    <img class="flex rounded" :src="get_Image(trip.tour_id, 900, 0)" :alt="imageAltText(trip.tour_id)">
                </div>
                <div v-if="zeroPriceCheck(trip)" class="content lg:flex-grow" :class="{'hidden': !isMobile()}">
                    <div class="innner">
                        <h5>{{getProductName(trip)}}</h5>
                        <ul class="route" :hidden="!valid_Times(trip_ends(trip.departure_date, trip.tour_id))"> 
                            <li>
                                <p class="route-location" :hidden="start_location(trip.tour_id) == null">Starts in {{start_location(trip.tour_id)}}</p>
                                <span class="route-date">{{trip_starts(trip.departure_date, trip.tour_id)}}</span>
                                <span class="route-date">{{trip_checkintime(trip.tour_id)}}</span>
                            </li>
                            <li>
                                <p class="route-location" :hidden="end_location(trip.tour_id) == null">Ends in {{end_location(trip.tour_id)}}</p>
                                <span class="route-date">{{trip_ends(trip.departure_date, trip.tour_id)}}</span>
                            </li>
                        </ul>
                        <p :hidden="!add_on_tour(trip)"><span v-html="FormatDescription(get_Tour_Details(trip.tour_id)?.marketing_information?.description)"></span></p>
                    </div>
                    <div v-if="!is_non_listed_tour(tour_id)" class="see-more flex" :hidden="add_on_tour(trip)">
                        <p class="icon">
                            <a v-if="!add_on_tour(trip)" @click="tour_selected(trip.tour_id)">
                                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-route fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="route" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432 352H320c-26.5 0-48-21.5-48-48s21.5-48 48-48h96s96-107 96-160-43-96-96-96-96 43-96 96c0 34.6 40.9 92.2 69.3 128H320c-44.1 0-80 35.9-80 80s35.9 80 80 80h112c26.5 0 48 21.5 48 48s-21.5 48-48 48H122.7c28.4-35.8 69.3-93.4 69.3-128 0-53-43-96-96-96S0 299 0 352s96 160 96 160h336c44.1 0 80-35.9 80-80s-35.9-80-80-80zM352 96c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zM32 352c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zm64-16c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32zM416 80c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32z" class=""></path></svg>
                                See full itinerary
                            </a>
                            <a v-if="add_on_tour(trip)" @click="tour_selected(trip.tour_id)">
                                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-route fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="route" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432 352H320c-26.5 0-48-21.5-48-48s21.5-48 48-48h96s96-107 96-160-43-96-96-96-96 43-96 96c0 34.6 40.9 92.2 69.3 128H320c-44.1 0-80 35.9-80 80s35.9 80 80 80h112c26.5 0 48 21.5 48 48s-21.5 48-48 48H122.7c28.4-35.8 69.3-93.4 69.3-128 0-53-43-96-96-96S0 299 0 352s96 160 96 160h336c44.1 0 80-35.9 80-80s-35.9-80-80-80zM352 96c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zM32 352c0-35.3 28.7-64 64-64s64 28.7 64 64c0 20.4-30.1 68-64 110.4-34-42.6-64-90.6-64-110.4zm64-16c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32zM416 80c-21.2 0-21.1 32 0 32 21.2 0 21.1-32 0-32z" class=""></path></svg>
                                See add-on description
                            </a>
                        </p>
                    </div>
                </div>

                
                <div v-if="zeroPriceCheck(trip)" class="price flex-none w-full lg:w-44">
                    <div class="inner relative h-full">
                        <p class="total"><strong>{{get_price(trip)}}</strong> <small>{{get_Currency()}}</small></p>
                        <ul class="list-none float-left lg:float-none">
                            <div :hidden="!valid_Times(trip_ends(trip.departure_date, trip.tour_id))">
                                <li class="inline-block lg:block mr-3">
                                    <span class="icon small inline text-sm lg:text-base" :hidden="add_on_tour(trip)">
                                        <div v-if="duration(trip?.tour_id) != ''">
                                            <div v-if="isAAT()">
                                                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-clock fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" class=""></path></svg>
                                                {{duration(trip?.tour_id)}}
                                            </div>
                                            <div v-else>
                                                <svg class="inline -mt-1 mr-2 svg-inline--fa fa-clock fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" class=""></path></svg>
                                                {{durationInDays(trip?.tour_id)}}
                                            </div>
                                        </div>
                                    </span>
                                </li>
                            </div>
                            <li class="inline-block lg:block mr-3 relative">
                                <basket_item :add_on="add_on_tour(trip)" :trip="trip" :parent_tour_id="this.$store.getters.get_Basket?.trips[0]?.tour_id"></basket_item>
                            </li>
                            <li class="inline-block lg:block mr-3 relative">
                                <button v-if="is_non_listed_tour(tour_id)" type="button" class="text-sm lg:text-base" :hidden="add_on_tour(trip)">
                                    <span class="icon small inline">
                                        <svg class="inline small w-4 -mt-1 mr-1 svg-inline--fa fa-user fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
                                    </span>
                                    {{passenger_total(trip?.tour_id)}}
                                </button>
                                <button v-else type="button" class="text-sm lg:text-base" :hidden="add_on_tour(trip)">
                                    <div class="text-2xl">{{item_total(trip?.tour_id)}}</div>
                                </button>
                            </li>
                            <li class="inline-block lg:block mr-3">
                                <button type="button" class="edit text-sm text-body font-semibold hover:text-action" @click="tour_edit(trip?.tour_id)" :hidden="add_on_tour(trip) == true || trip.booking_identifier != null">
                                    <svg class="inline -mt-1 mr-2 w-4 svg-inline--fa fa-edit fa-w-18 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M417.8 315.5l20-20c3.8-3.8 10.2-1.1 10.2 4.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h292.3c5.3 0 8 6.5 4.2 10.2l-20 20c-1.1 1.1-2.7 1.8-4.2 1.8H48c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V319.7c0-1.6.6-3.1 1.8-4.2zm145.9-191.2L251.2 436.8l-99.9 11.1c-13.4 1.5-24.7-9.8-23.2-23.2l11.1-99.9L451.7 12.3c16.4-16.4 43-16.4 59.4 0l52.6 52.6c16.4 16.4 16.4 43 0 59.4zm-93.6 48.4L403.4 106 169.8 339.5l-8.3 75.1 75.1-8.3 233.5-233.6zm71-85.2l-52.6-52.6c-3.8-3.8-10.2-4-14.1 0L426 83.3l66.7 66.7 48.4-48.4c3.9-3.8 3.9-10.2 0-14.1z" class=""></path></svg>
                                    Edit Tour
                                </button>
                            </li>
                        </ul>
                        <button v-if="!deletedTour(trip.tour_id)" class="trash" @click="Remove_Trip(trip.tour_id)" :hidden="trip.booking_identifier != null">
                            <span class="icon small inline">
                                <svg class="inline -mt-1 mr-1 svg-inline--fa fa-trash-alt fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z" class=""></path></svg>
                            </span>
                            Remove
                        </button>
                        <button v-else :disable="true" class="trash" :hidden="trip.booking_identifier != null">
                            <span class="icon small inline">
                                <svg class="animate-spin h-5 w-5 mr-3 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor"><path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"></path></svg>
                            </span>
                            Removing...
                        </button>
                    </div>
                </div>
            </div>

            

        </div>
    </div>
    <div class="slideout" :hidden="isHidden" 
         x-transition:enter="transition ease-out duration-500 -mr-64"
         x-transition:enter-start=""
         x-transition:enter-end="transform -translate-x-64"
         x-transition:leave="transform ease-out duration-75"
         x-transition:leave-start=""
         x-transition:leave-end="transform translate-x-64"
         style="z-index: 201 !important;">
        <div class="slideout-inner">
            <button class="close no-bg" @click="isHidden = true"></button>
            <div class="slideout-content">
                <itinerary :tour_id="tour_id" :banner="true" ref="itinerary" v-if="!isHidden"></itinerary>
            </div>

        </div>

        <div class="slideout-bg" @click="isHidden = true"></div>

    </div>
    <div class="slideout" :hidden="isHidden_Edit_Tour"
         x-transition:enter="transition ease-out duration-500 -mr-64"
         x-transition:enter-start=""
         x-transition:enter-end="transform -translate-x-64"
         x-transition:leave="transform ease-out duration-75"
         x-transition:leave-start=""
         x-transition:leave-end="transform translate-x-64"
         style="z-index: 201 !important;">
        <div class="slideout-inner">
            <button class="close no-bg" @click="isHidden_Edit_Tour = true"></button>
            <p>Tour Details Changes</p>
            <div class="slideout-content">
                <tour_details ref="tour_details" @Add_To_Cart="Add_To_Cart"></tour_details>
            </div>
        </div>

        <div class="slideout-bg" @click="isHidden_Edit_Tour = true"></div>

    </div>
</template>

<script lang="ts" src="./basket.ts"></script>