﻿<template>
    <div class="form-group">

        <div class="w-full text-center mb-8">
            <h3 class="uppercase mt-0">RESET PASSWORD</h3>
        </div>

        <div class="form-data flex">
            <svg class="inline mt-4 mr-1 h-5 w-8 flex-none svg-inline--fa fa-user fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
            <input type="text" name="email" id="email" placeholder="Email Address" v-model="Request_Password_Reset.user_name" />
        </div>

        <p class="text-center"><div @click="Reset_Password()" class="button button-default w-full sm:w-3/4 rounded-full ">Reset Password</div></p>
        <p class="text-center text-s">Please check your email for you password reset link</p>
        <p class="text-center text-xs">{{getContactInfo()}}</p>

    </div>

    <div class="grid grid-cols-2">
        <div><div class="anchor text-sm textButton cursor-pointer" @click="Login()">Login</div></div>
        <div v-if="!hide_new_agent_link()" class="text-right text-sm textButton cursor-pointer"><div @click="Register()">New agent? Register here</div></div>
    </div>
</template>

<script lang="ts" src="./forgot_password_screen.ts"></script>