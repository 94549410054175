﻿<template>
    <div>
        <div class="lg:pb-24" >
            <div class="pb-6 lg:pb-60 lg:tourHeading" >
                <div class="lg:float-left float-center  lg:px-5">
                    <h3 class="tourTitle text-center lg:text-left">{{product?.seller_information?.sales_group?.group_name}}</h3>
                    <div class="lg:flex">
                        

                        <img class="image mx-auto rounded mb-6 lg:mb-12 lg:mb-2 h-52" :src="product?.marketing_information?.media[0]?.assets[0]?.asset_url + '?anchor=center&mode=crop&width=300&height=200'" :alt="imageAltText()">

                        <p class="order-first lg:mr-12 lg:text-left text-center mt-0">{{product?.marketing_information?.description.replace(/<\/?[^>]+(>|$)/g, "")}}</p>
                    </div>
                </div>  
            
            </div>
        </div>
        <!--booking notes pop-up-->
        <button v-if="!isAgent_Login() && GetBookingNotes() != ''" class="px-5 border-grey-default bg-form border-blank button text-black full" :disabled="true">{{GetBookingNotes()}}</button>
    </div>
</template>

<script lang="ts" src="./tour_heading.ts"></script>