﻿<template>

    <div class="w-full text-center mb-8">
        <h3 class="uppercase mt-0">AGENT LOGIN</h3>
        <p class="text-center text-s">Please login with your agency email address</p>
    </div>

    <div class="flex">
        <svg class="inline mt-4 mr-1 h-5 w-8 flex-none svg-inline--fa fa-user fa-w-14 fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
        <input v-on:keyup.enter="agent_login()" class="login login-text" type="text" name="user_name" id="user_name" v-model="Login_Request.user_name" />
    </div>

    <div class="flex">
        <svg class="inline mt-4 mr-1 h-5 w-8 flex-none feather feather-lock-w-14 fa-2x" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
        <input v-on:keyup.enter="agent_login()" class="login login-text" type="password" name="password" id="password" v-model="Login_Request.password" />
    </div>

    <p class="text-center">
            <button type="button" class="button button-book large full" @click="agent_login()">
                <svg class="animate-spin h-5 w-5 mr-3 inline" :class="{ hidden: !logging_in }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                    <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                </svg>
                {{loginText()}}
            </button>
    </p>

    <p class="text-center text-xs">{{getContactInfo()}}</p>


    <div class="grid grid-cols-2">
        <div><div class="anchor text-sm textButton cursor-pointer" @click="Forgot_Password()">Forgot password? Click here</div></div>
        <div v-if="!hide_new_agent_link()" class="text-right text-sm textButton cursor-pointer"><div @click="Register()">New agent? Register here</div></div>

    </div>

</template>

<script lang="ts" src="./agent_login.ts"></script>